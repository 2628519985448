import styled from 'styled-components';

const MobileMenu = styled.div`
  & .show {
    display: block;
  }
  & .sub-menu button {
    background: none;
    border: none;
    padding: 0;
    font-weight: bold;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const LogoContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const RedesContainer = styled.div`
  @media screen and (min-width: 640px) {
    display: flex !important;
    align-items: center;
    text-align: end;
  }
`;

const Slogan = styled.img`
  display: none;
  height: 70;
  @media screen and (min-width: 768px) {
    display: inline;
  }
`;

export {
  MobileMenu,
  HeaderContainer,
  LogoContainer,
  RedesContainer,
  Slogan,
}