import React from 'react';
import * as data from '../../util/data';
import { FiltersList } from '../../components/FiltersList'
import Spinner from '../../components/Spinner';
import Seo from '../../components/Seo';

const gradeFilterValues = [
  { value: "0", label: "Todos" },
  { value: "1", label: "1° Preescolar" },
  { value: "2", label: "2° Preescolar" },
  { value: "3", label: "3° Preescolar" },
  { value: "4", label: "1° Primaria" },
  { value: "5", label: "2° Primaria" },
  { value: "6", label: "3° Primaria" },
  { value: "7", label: "4° Primaria" },
  { value: "8", label: "5° Primaria" },
  { value: "9", label: "6° Primaria" },
  { value: "10", label: "1° Secundaria" },
  { value: "11", label: "2° Secundaria" },
  { value: "12", label: "3° Secundaria" },
  { value: "13", label: "1° Preparatoria" },
  { value: "14", label: "2° Preparatoria" },
  { value: "15", label: "3° Preparatoria" },
]

class PlanLector extends React.Component {
  constructor(props) {
    super(props);
    const initialState = {
      books: [],
      filters: {
        grade: "0",
      },
      loading: false,
    }
    this.state = initialState;
  }

  componentDidMount() {
    this.setState({ loading: true });
    const booksPromise = data.booksPromise();
    booksPromise.then(responseData => {
      const booksArray = Object.entries(JSON.parse(responseData)).map((book) => (
        { ...book[1], id: book[0] }
      ));
      this.setState({
        books: booksArray,
      });
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        <Seo
          title="Plan Lector"
          description="Libros para los programas escolares de lectura. Recomendaciones por grado escolar para maestros y alumnos."
        />
        <section className="st-content wrapper cf">
          <Spinner visible={this.state.loading} />
          <div className="cat-filters cf">
            <div id="filterA">
              <span>Grado escolar</span>
              <FiltersList>
                {
                  gradeFilterValues.filter((entry, index) => index === 0).map(gradeEntry => (
                    <li
                      onClick={() => this.setState({ filters: { ...this.state.filters, grade: gradeEntry.value } })}
                      key={`grade-${gradeEntry.value}`}
                    >
                      <label className={this.state.filters.grade === gradeEntry.value ? "active" : ""}>
                        <input name="grado" type="radio" value={gradeEntry.value} />{gradeEntry.label}</label>
                    </li>
                  ))
                }
                <span>Preescolar</span>
                {
                  gradeFilterValues.filter((entry, index) => index > 0 && index < 4).map(gradeEntry => (
                    <li
                      onClick={() => this.setState({ filters: { ...this.state.filters, grade: gradeEntry.value } })}
                      key={`grade-${gradeEntry.value}`}
                    >
                      <label className={this.state.filters.grade === gradeEntry.value ? "active" : ""}>
                        <input name="grado" type="radio" value={gradeEntry.value} />{gradeEntry.label}</label>
                    </li>
                  ))
                }
                <span>Primaria</span>
                {
                  gradeFilterValues.filter((entry, index) => index > 3 && index < 10).map(gradeEntry => (
                    <li
                      onClick={() => this.setState({ filters: { ...this.state.filters, grade: gradeEntry.value } })}
                      key={`grade-${gradeEntry.value}`}
                    >
                      <label className={this.state.filters.grade === gradeEntry.value ? "active" : ""}>
                        <input name="grado" type="radio" value={gradeEntry.value} />{gradeEntry.label}</label>
                    </li>
                  ))
                }
                <span>Secundaria</span>
                {
                  gradeFilterValues.filter((entry, index) => index > 9 && index < 13).map(gradeEntry => (
                    <li
                      onClick={() => this.setState({ filters: { ...this.state.filters, grade: gradeEntry.value } })}
                      key={`grade-${gradeEntry.value}`}
                    >
                      <label className={this.state.filters.grade === gradeEntry.value ? "active" : ""}>
                        <input name="grado" type="radio" value={gradeEntry.value} />{gradeEntry.label}</label>
                    </li>
                  ))
                }
                <span>Preparatoria</span>
                {
                  gradeFilterValues.filter((entry, index) => index > 12 && index < 16).map(gradeEntry => (
                    <li
                      onClick={() => this.setState({ filters: { ...this.state.filters, grade: gradeEntry.value } })}
                      key={`grade-${gradeEntry.value}`}
                    >
                      <label className={this.state.filters.grade === gradeEntry.value ? "active" : ""}>
                        <input name="grado" type="radio" value={gradeEntry.value} />{gradeEntry.label}</label>
                    </li>
                  ))
                }
              </FiltersList>
              <div className="dropdown-label"></div>
            </div>
          </div>
          <div className="cat-list" id="cat-list">
            {
              this.state.books &&
              this.state.books
                .filter(book => (this.state.filters.grade !== "0" ? book.grade.includes(this.state.filters.grade) : book.grade))
                .map(book => (
                  <div className="cat-libro" key={`catalog-${book.id}`}>
                    <a href={`/libros/${book.id}`}>
                      <img
                        className="cover"
                        src={`${book.cover}`}
                        alt={book.name}
                      />
                      <div className="title">{book.name}</div>
                    </a>
                  </div>
                ))
            }
          </div>
        </section>
      </>
    )
  }
}

export default PlanLector;
