import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Seo from '../../components/Seo';
import * as data from '../../util/data';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function removeDiacritic(str) {
  var diacritics = ["á", "é", "í", "ó", "ú"];
  var vowels = ["a", "e", "i", "o", "u"];
  var newString = str;
  diacritics.forEach(function (char) {
    newString = newString.replace(new RegExp(char, "g"), vowels[diacritics.indexOf(char)]);
  });
  return newString;
}

function search(obj, locations, query) {
  const strippedQuery = removeDiacritic(query.toLowerCase());
  const resultsArray = [];
  Object.keys(obj).forEach(function (element) {
    locations.forEach(function (location) {
      var searchableString;
      if (Array.isArray(obj[element][location])) {
        obj[element][location].forEach(function (subelement) {
          searchableString = removeDiacritic(subelement.toLowerCase());
          if (searchableString.indexOf(strippedQuery) > -1 && !resultsArray.includes(element))
            resultsArray.push(element);
        });
      } else {
        searchableString = removeDiacritic(obj[element][location].toLowerCase());
        if (searchableString.indexOf(strippedQuery) > -1 && !resultsArray.includes(element))
          resultsArray.push(element);
      }
    });
  });
  return resultsArray;
}

const Busqueda = () => {
  const [books, setBooks] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [artists, setArtists] = useState([]);
  const [results, setResults] = useState([]);
  const [message, setMessage] = useState('Obteniendo resultados...');
  const query = useQuery();
  const term = query.get('q')?.trim();

  useEffect(() => {
    async function fetchData() {
      const booksData = await data.booksPromise();
      setBooks(JSON.parse(booksData));
      const artistsData = await data.artistsPromise();
      setArtists(JSON.parse(artistsData));
      const authorsData = await data.writersPromise();
      setAuthors(JSON.parse(authorsData));
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(authors).length > 0 && Object.keys(artists).length > 0 && Object.keys(books).length > 0 && results.length === 0) {
      executeSearch();
    }
    // eslint-disable-next-line
  }, [authors, artists, books]);

  const executeSearch = () => {
    let resultsArray = [];
    resultsArray = resultsArray.concat(search(books, ["resumen", "author", "artist", "name", "themes"], term).map(element => ({
      label: books[element].name,
      url: `libros/${element}`,
    })));
    resultsArray = resultsArray.concat(search(artists, ["bio", "name"], term).map(element => ({
      label: artists[element].name,
      url: `autores/${element}`,
    })));
    resultsArray = resultsArray.concat(search(authors, ["bio", "name"], term).map(element => ({
      label: authors[element].name,
      url: `autores/${element}`,
    })));
    if (resultsArray.length === 0) {
      setMessage(`No hay resultados para <b>${term}</b>.`);
    } else {
      setMessage(`Tus resultados para la búsqueda de <b>${term}</b>:`);
    }

    setResults(resultsArray);
  }

  return (
    <>
      <Seo
        title="Búsqueda"
      />
      <div
        className="st-content wrapper cf"
      >
        {
          term ?
          <div className="search-title" dangerouslySetInnerHTML={{ __html: message }} />
          :
          <div className="search-title">Ingresa un término para iniciar la búsqueda</div>
        }
        <div className="search-results">
          {
            results.map((result, index) => (
              <a className="single-result" href={result.url} key={`result-${index}`}>
                <div>{result.label}</div>
              </a>
            ))
          }
        </div>
      </div>
    </>
  );
}

export default Busqueda;
