import React, { useEffect, useState } from 'react';
import {
  Link, useLocation
} from 'react-router-dom';
/* Images */
import Logo from "../../assets/img/header/naranjo20.svg";
import Slogan from "../../assets/img/header/20aleer.svg";
import iconTienda from "../../assets/img/icons/icono_tienda_naranja.svg"
import iconFb from "../../assets/img/icons/icono_fb.svg";
import iconTw from "../../assets/img/icons/icono_tw.svg";
import iconIns from "../../assets/img/icons/icono_ins.svg";
import iconYt from "../../assets/img/icons/icono_yt.svg";
import iconBurger from "../../assets/img/icons/mobile/menu_burger.svg";
import iconTriangle from "../../assets/img/icons/mobile/menu_triangulo.svg";
import iconMobileEbooks from "../../assets/img/icons/mobile/icono_ebooks.svg";
import iconMobileVideos from "../../assets/img/icons/mobile/icono_videos.svg";
import iconMobileBlog from "../../assets/img/icons/mobile/icono_blog.svg";
import iconMobilePlector from "../../assets/img/icons/mobile/icono_plector.svg";
import iconMobileRights from "../../assets/img/icons/mobile/icono_rights.svg";
import iconMobileNewsletter from "../../assets/img/icons/mobile/icono_newsletter.svg"; 
import bopLogo from "../../assets/img/header/BOP-web.png";
import { HeaderContainer, LogoContainer, MobileMenu, RedesContainer, Slogan as SloganImg } from './styles';
import _ from 'lodash';

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileSubMenu, setShowMobileSubMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth > 1024 ? 1024 : window.innerWidth < 640 ? 640 : window.innerWidth);
  let location = useLocation();

  useEffect(() => {
    setShowMobileMenu(false);
    setShowMobileSubMenu(false);
  }, [location]);

  useEffect(() => {
    if (windowWidth > 640) {
      setShowMobileMenu(false);
      setShowMobileSubMenu(false);
    }
  }, [windowWidth]);

  const handleWindowResize = _.debounce(function updateWindowWidth () {
    if (
      windowWidth >= 640
      && windowWidth <= 1024
    ) {
      setWindowWidth(window.innerWidth > 1024 ? 1024 : window.innerWidth < 640 ? 640 : window.innerWidth);
    }
  }, 400);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return _ => {
      window.removeEventListener('resize', handleWindowResize);
    }
  });

  return (
    <div>
      <header className="st-header">
        <HeaderContainer>
          <div className="menu-controls">
            <div
              id="mobile-main"
              data-control="menu"
              onClick={() => {
                setShowMobileMenu(!showMobileMenu);
                showMobileSubMenu && setShowMobileSubMenu(false);
              }}
            >
              <img alt="Menú" src={iconBurger}/>
            </div>
            <div
              id="mobile-sub"
              data-control="sub-menu"
              onClick={() => {
                setShowMobileSubMenu(!showMobileSubMenu);
                showMobileMenu && setShowMobileMenu(false);
              }}
            >
              <img alt="Sub-menú" src={iconTriangle} />
            </div>
          </div>
          <LogoContainer>
            <SloganImg
              alt="Veinte a leer"
              src={Slogan}
              style={{
                height: 70,
              }}
            />
            <Link to="/"><img alt="Ediciones El Naranjo" src={Logo} style={{ height: 52 }} /></Link>
            <img alt="BOP - Ediciones El Naranjo" src={bopLogo} style={{
              height: 75,
            }} />
          </LogoContainer>
          <RedesContainer className="redes">
            <ul>
              <li><a href="https://es-la.facebook.com/EdicionesElNaranjo.Oficial/" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconFb} /></a></li>
              <li><a href="https://twitter.com/el_naranjo" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconTw} /></a></li>
              <li><a href="https://www.instagram.com/edicioneselnaranjo/" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconIns} /></a></li>
              <li><a href="https://www.youtube.com/user/edicioneselnaranjo" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconYt} /></a></li>
            </ul>
          </RedesContainer>
        </HeaderContainer>
        <MobileMenu className="mobile-nav">
          <div className="idioma hide">
            <ul>
              <li className="selected"><button>ESPAÑOL</button></li>
              <li><button>ENGLISH</button></li>
            </ul>
          </div>
          <nav>
            <ul className={`menu ${showMobileMenu && 'show'}`}>
              <li><Link to="/">Inicio</Link></li>
              <li><Link to="/nosotros">Nosotros</Link></li>
              <li><Link to="/catalogo">Catálogo</Link></li>
              <li><Link to="/autores">Autores</Link></li>
              <li><Link to="/planlector">Plan Lector</Link></li>
              <li><Link to="/contacto">Contacto</Link></li>
            </ul>
            <ul className={`sub-menu ${showMobileSubMenu && 'show'}`}>
              <li><Link to="/ebooks"><img alt="Ebooks" src={iconMobileEbooks} />Ebooks</Link></li>
              <li><Link to="/videos"><img alt="Videos" src={iconMobileVideos} />Videos</Link></li>
              {/* <li><Link to="/blog"><img alt="pending" src={iconMobileBlog} />Blog</Link></li> */}
              <li><Link to="/planlector"><img alt="Plan Lector" src={iconMobilePlector} />Plan Lector</Link></li>
              <li><Link to="/derechos"><img alt="Derechos" src={iconMobileRights} />Rights</Link></li>
              {/* <li><button className="button_newsletter"><img alt="Newsletter" src={iconMobileNewsletter} />Newsletter</button></li> */}
            </ul>
          </nav>
        </MobileMenu>
      </header>
      <nav className="st-nav wrapper">
        <ul className="menu">
          <div>
            <li><Link to="/">Inicio</Link></li>
            <li><Link to="/nosotros">Nosotros</Link></li>
            <li><Link to="/catalogo">Catálogo</Link></li>
            <li><Link to="/autores">Autores</Link></li>
            <li><Link to="/planlector">Plan Lector</Link></li>
          </div>
          <div>
            <li><Link to="/contacto">Contacto</Link></li>
            <li className="search">
              <span className="search-magn"><i className="fa fa-search" aria-hidden="true"></i></span>
              <form action="/buscar">
                <input type="search" name="q" placeholder="Buscar" />
              </form>
            </li>
            <li className="tienda"><a href="https://edicioneselnaranjo.com.mx/tienda/"><img alt="pending" src={iconTienda} /><br />Tienda</a></li>
          </div>
        </ul>
        <ul className="redes">
          <li><a href="https://es-la.facebook.com/EdicionesElNaranjo.Oficial/" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconFb} /></a></li>
          <li><a href="https://twitter.com/el_naranjo" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconTw} /></a></li>
          <li><a href="https://www.instagram.com/edicioneselnaranjo/" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconIns} /></a></li>
          <li><a href="https://www.youtube.com/user/edicioneselnaranjo" target="_blank" rel="noopener noreferrer"><img alt="pending" src={iconYt} /></a></li>
          <li className="tienda"><a href="https://edicioneselnaranjo.com.mx/tienda/"><span>Tienda</span>&nbsp;<img alt="pending" src={iconTienda} /></a></li>
          <li className="search">
            <span className="search-close">
              <i className="fa fa-times" aria-hidden="true" />
            </span>
            <form action="/buscar">
              <input type="search" name="Buscar" placeholder="Buscar" />
            </form>
            <span className="search-magn">
              <i className="fa fa-search" aria-hidden="true" />
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;