import React from 'react';
import Seo from '../../components/Seo';
import Spinner from '../../components/Spinner';
import * as data from '../../util/data';

export default class Autor extends React.Component {
  constructor(props) {
    super(props);
    const initialState = {
      current: {},
      books: [],
      loading: false,
    }
    this.state = initialState;
  }

  componentDidMount() {
    this.setState({ loading: true });
    const writersRequest = data.writersPromise();
    const illustratorsRequest = data.artistsPromise();
    const booksRequest = data.booksPromise();
    Promise.all([writersRequest, illustratorsRequest, booksRequest]).then(responsesData => {
      this.setState({
        current: { ...JSON.parse(responsesData[0]), ...JSON.parse(responsesData[1]) }[this.props.match.params.authorId],
        books: [
          ...new Set(
            Object.entries(JSON.parse(responsesData[2]))
              .map(value => ({ ...value[1], id: value[0] }))
              .filter(value =>
                value.author.includes(this.props.match.params.authorId) ||
                value.artist.includes(this.props.match.params.authorId)
              )
          )
        ],
      });
      this.setState({ loading: false });
    });
  }

  createMarkup(rawstring) {
    return { __html: rawstring };
  }

  render() {
    return (
      <>
        <Seo
          title={this.state.current.name || ''}
          description={this.state.current.bio || ''}
        />
        <section className="st-content wrapper cf author">
          <Spinner visible={this.state.loading} />
          <div className="author-info" id="author-info" >
            <div className="foto">
              <img
                src={this.state.current.picture}
                alt={this.state.current.name}
                id="author-picture"
              />
            </div>
            <div className="data">
              <h2>{this.state.current.name}</h2>
              <div
                id="author-bio"
                dangerouslySetInnerHTML={this.createMarkup(this.state.current.bio)}
              />
            </div>
          </div>
          <div className="author-books" id="author-books">
            {
              this.state.books &&
              this.state.books.map(value => (
                <div
                  className="cat-libro"
                  key={`autor-libro-${value.id}`}
                >
                  <a href={`/libros/${value.id}`}>
                    <div className="cover-container">
                      <img
                        className="cover"
                        src={value.cover}
                        alt={value.name}
                      />
                    </div>
                    <div className="title">
                      {value.name}
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </section>
      </>
    )
  }
}
