import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import iconAmazon from "../../assets/img/icons/amazon_img.svg";
import iconIBooks from "../../assets/img/icons/ibooks_img.svg";
import banner01 from "../../assets/img/routes/ebooks/banner_ebooks_01.jpg";
import banner02 from "../../assets/img/routes/ebooks/banner_ebooks_02.jpg";
import banner03 from "../../assets/img/routes/ebooks/banner_ebooks_03.jpg";
import banner04 from "../../assets/img/routes/ebooks/banner_ebooks_04.jpg";
import banner05 from "../../assets/img/routes/ebooks/banner_ebooks_05.jpg";
import banner06 from "../../assets/img/routes/ebooks/banner_ebooks_06.png";
import banner07 from "../../assets/img/routes/ebooks/banner_ebooks_07.jpg";
import Seo from '../../components/Seo';

const banners = [
  banner01,
  banner02,
  banner03,
  banner04,
  banner05,
  banner06,
  banner07,
]

const Ebooks = () => {
  return (
    <>
      <Seo
        title="Ebooks"
        description="La versión electrónica de tus libros favoritos de Ediciones El Naranjo. Leer en digital es una experiencia que complementa la lectura impresa."
      />
      <section className="st-content wrapper page-ebooks">
        <div className="sidebar">
          <div className="ebooks-banner">
            <Carousel className="banner-component">
              {
                banners.map((banner, index) => (
                  <Carousel.Item key={`slide-${index}`}>
                    <img
                      className="d-block w-100"
                      src={banner}
                      alt={`Slide ${index}`}
                    />
                  </Carousel.Item>
                ))
              }
            </Carousel>
          </div>
        </div>
        <div className="main-text text-section">
          <div className="parag txtblock">
            <span>En El Naranjo reconocemos la importancia de los medios digitales y su potencial para acercar la lectura a más niños y jóvenes. Es por eso que gran parte de nuestro catálogo está disponible en su versión electrónica. Conoce los títulos digitales e interactivos que se encuentran en las plataformas de iBooks y Amazon Kindle.</span>
          </div>
          <div className="platforms">
            <a href='https://www.amazon.com.mx/s/ref=dp_byline_sr_ebooks_3?ie=UTF8&text=Ediciones+El+Naranjo&search-alias=digital-text&field-author=Ediciones+El+Naranjo&sort=relevancerank' target="blank">
              <img src={iconAmazon} alt="Amazon" />
            </a>
            <a href="http://edicioneselnaranjo.com.mx/ebooks" className="disabled">
              <img src={iconIBooks} alt="iBooks" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ebooks;
