import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  opacity: .6;
  .spinner {
    position: relative;
    top: 50%;
    margin: 0 auto;
    display: block;
    -webkit-animation: rotator 1.4s linear infinite;
    animation: rotator 1.4s linear infinite;
  }

  @-webkit-keyframes rotator {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
  }

  @keyframes rotator {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  }

  @-webkit-keyframes colors {
    0% {
        stroke: #4285f4;
    }
    25% {
        stroke: #de3e35;
    }
    50% {
        stroke: #f7c223;
    }
    75% {
        stroke: #1b9a59;
    }
    100% {
        stroke: #4285f4;
    }
  }

  @keyframes colors {
    0% {
        stroke: #4285f4;
    }
    25% {
        stroke: #de3e35;
    }
    50% {
        stroke: #f7c223;
    }
    75% {
        stroke: #1b9a59;
    }
    100% {
        stroke: #4285f4;
    }
  }

  @-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        -webkit-transform: rotate(450deg);
        transform: rotate(450deg);
    }
  }

  @keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        -webkit-transform: rotate(450deg);
        transform: rotate(450deg);
    }
  }
`;