import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Link
} from 'react-router-dom';
import { SubmenuButton } from '../../layout/components/styles';
import ImgEbooks from "../../assets/img/icons/icono_ebooks.svg";
import ImgVideos from "../../assets/img/icons/icono_videos.svg";
import ImgBlog from "../../assets/img/icons/icono_blog.svg";
import ImgPlanLector from "../../assets/img/icons/icono_plector.svg";
import ImgDerechos from "../../assets/img/icons/icono_rights.svg";
import ImgNewsletter from "../../assets/img/icons/icono_newsletter.svg";
import styled from 'styled-components';
import jsonp from 'jsonp';
import toQueryString from 'to-querystring';

const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  font-family: "News Gothic MT", sans-serif;
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }
  span {
    color: #E4610A;
  }
  .buttonContainer {
    margin: 0;
    align-items: center;
  }
  button {
    border: none;
    background: #E4610A;
    color: white !important;
    padding: .5em;
    max-width: 50%;
    margin: 0 auto;
  }
  button:hover {
    background: white;
    box-shadow: inset 0 0 0 2px #E4610A;
    color: #E4610A !important;
  }
`;

const SignupTitle = styled.div`
  color: #E4610A;
  font-family: "News Gothic MT", sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
`;

const Footer = () => {
  const [modalShow, setModalShow] = useState(false);
  const [formEmail, setFormEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [formStatus, setFormStatus] = useState('initial');

  const clearForm = () => {
    setFirstName('');
    setFormEmail('');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = "https://edicioneselnaranjo.us5.list-manage.com/subscribe/post-json?u=da2fe2c83ae3c7a92945ea6c4&amp;id=add1e98aac";
    const formData = {
      FNAME: firstName,
      EMAIL: formEmail,
    }
    jsonp(
      `${url}&${toQueryString(formData)}`,
      {
        param: "c"
      },
      (err, data) => {
      if (err) {
        console.log("error", err);
        setFormStatus('error');
        clearForm();
      } else if (data.result !== "success") {
        console.log("error", data.msg);
        setFormStatus('error');
        clearForm();
      } else {
        console.log("success", data.msg);
        setFormStatus('success');
        clearForm();
      }
    });
  }

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          clearForm();
        }}
        onShow={() => {
          setFormStatus('initial');
        }}
        centered
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <SignupTitle>
              {
                formStatus === 'initial' && '¿Quieres saber más de nuestros libros?'
              }
              {
                formStatus === 'error' && 'Error. Por favor intenta más tarde'
              }
              {
                formStatus === 'success' && '¡Gracias por suscribirte a nuestro boletín!'
              }
            </SignupTitle>
          </Modal.Title>
        </Modal.Header>
        {
          formStatus === 'initial' &&
            <Modal.Body>
              <SignupForm
                onSubmit={event => handleSubmit(event)}
              >
                <div>
                  <span>Nombre</span>
                  <input
                    required
                    type="string"
                    name="FNAME"
                    maxLength="50"
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)}
                  />
                </div>
                <div>
                  <span>Email</span>
                  <input
                    required
                    type="email"
                    name="EMAIL"
                    maxLength="50"
                    value={formEmail}
                    onChange={event => setFormEmail(event.target.value)}
                  />
                </div>
                <div className="buttonContainer">
                  <button>
                    ¡Suscribirme al boletín!
                  </button>
                </div>
              </SignupForm>
            </Modal.Body>
        }
      </Modal>
      <nav className="secondary wrapper">
        <ul className="sub-menu cf">
          <li><Link to="/ebooks"><img alt="Ebooks" src={ImgEbooks} />Ebooks</Link></li>
          <li><Link to="/videos"><img alt="Videos" src={ImgVideos} />Videos</Link></li>
          {/* <li><Link to="/blog"><img alt="Blog" src={ImgBlog} />Blog</Link></li> */}
          <li><Link to="/planlector"><img alt="Plan Lector" src={ImgPlanLector} />Plan Lector</Link></li>
          <li><Link to="/derechos"><img alt="Derechos" src={ImgDerechos} />Rights</Link></li>
          <li>
            <SubmenuButton
              onClick={() => setModalShow(true)}
            >
              <img alt="Newsletter" src={ImgNewsletter} />
              Newsletter
            </SubmenuButton>
          </li>
        </ul>
      </nav>
      <footer className="st-footer wrapper">
        <div className="copyright">
          <small>&copy; Copyright {(new Date()).getFullYear()}, Ediciones El Naranjo todos los derechos reservados.&nbsp;<br />Teléfono: <a href="tel:+525556521974">+52 (55) 5652 1974</a></small>
        </div>
      </footer>
      {/* Mailchimp */}
      <script type="text/javascript" src="//downloads.mailchimp.com/js/signup-forms/popup/embed.js" data-dojo-config="usePlainJson: true, isDebug: false"></script>
      {/* AddThis */}
      <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-598d6764c5fc5255"></script>
    </div>
  );
}

export default Footer;