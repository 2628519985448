import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Seo from '../../components/Seo';
import Spinner from '../../components/Spinner';

export default function Videos() {
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    setLoading(true);
    const url = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLkfl0UJlLQ63oIpnfPKDsV2OUKk_DVdWz&key=AIzaSyCX3-oCL8UHQ7VyE4hK_XjQwALX6SWXcbQ';
    fetch(url, {
      method: 'GET',
    }).then(data => data.json().then(videosResponse => {
      setVideos(videosResponse.items);
      setLoading(false);
    }));
  }, []);

  return (
    <>
      <Seo
        title="Videos"
        description="Mira los booktrailers de los libros que te interesan."
      />
      <section
        className="st-container wrapper"
        id="videos-section"
      >
        <Spinner visible={loading} />
        <Modal
          size="lg"
          show={modalShow}
          onHide={() => setModalShow(false)}
          centered
        >
          <Modal.Body
            style={{
              height: "50vh",
              
            }}
          >
            <iframe
              title="Booktrailer"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${modalContent}?rel=0&amp;showinfo=0`}
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </Modal.Body>
        </Modal>
        {
          videos &&
          videos.map(video => (
            <div
              className="single-video-container"
              key={`video-${video.snippet.title}`}
              onClick={() => {
                setModalContent(video.snippet.resourceId.videoId);
                setModalShow(true);
              }}
            >
              {/* <p key={`video-${video.snippet.title}`}>{video.snippet.title}
                <iframe
                  title={video.snippet.title}
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}?rel=0&amp;showinfo=0`}
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              </p> */}
              <div
                className="video-image"
                style={{
                  background: `url(${video.snippet.thumbnails.maxres?.url || video.snippet.thumbnails.standard?.url || video.snippet.thumbnails.high?.url}) repeat scroll center center / cover`,
                }}
              />
              <div
                className="video-data"
              >
                <span>
                  {video.snippet.title}
                </span>
              </div>
            </div>
          ))
        }
      </section>
    </>
  );
}
