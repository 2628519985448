import React from "react";
import { Accordion, Card } from "react-bootstrap";
import styled from "styled-components";
import { Wrapper } from "../../layout/components/styles";
import GoogleMapReact from "google-map-react";

import marker from "../../assets/img/icons/marker.png";
import programaImg from "../../assets/img/routes/veinte/Programa.jpg";
import bannerA from "../../assets/img/routes/veinte/Banner_minisitio.jpg";

const Marker = () => {
  return (
    <div>
      <img
        style={{ marginLeft: "-21px", marginTop: "-78px" }}
        alt="El Naranjo"
        src={marker}
      />
    </div>
  );
};

const mapData = {
  lat: 19.34205,
  lng: -99.192727,
  center: {
    lat: 19.3419,
    lng: -99.1926,
  },
  zoom: 17,
};

const CustomWrapper = styled(Wrapper)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const BannerA = styled("div")({
  background: "#009cdf",
  padding: "20px",
  color: "white",
});

const BannerB = styled("div")({
  background: "#e4610a",
  padding: "20px",
  color: "white",
});

const CustomAccordion = styled(Accordion)({
  ".card": {
    border: "none",
    borderRadius: "0",
  },
  ".card-header": {
    color: "white",
    cursor: "pointer",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  ".card:nth-child(odd) .card-header": {
    background: "#009cdf",
  },
  ".card:nth-child(even) .card-header": {
    background: "#e4610a",
  },
});

const Map = styled("div")({
  "& > div": {
    paddingTop: "50% !important",
  },
});

const StyledImg = styled("img")({
  width: "100%",
});

const Veinte = () => {
  return (
    <CustomWrapper>
      <BannerA>
        <StyledImg src={bannerA} />
      </BannerA>
      {/* <BannerB>Banner B</BannerB> */}
      <CustomAccordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            ¿Cómo llegar?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p>
                Jardín del Arte, San Ángel, Álvaro Obregón, CDMX
                <br />
                Importante: entrada por calle Arteaga
                <br />
                Atrás del Mercado de San Ángel
                <br />
                Estación de Metrobús: Doctor Gálvez
              </p>
              <Map>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCX3-oCL8UHQ7VyE4hK_XjQwALX6SWXcbQ",
                  }}
                  defaultCenter={mapData.center}
                  defaultZoom={mapData.zoom}
                >
                  <Marker
                    lat={mapData.lat}
                    lng={mapData.lng}
                    text="El Naranjo"
                  />
                </GoogleMapReact>
              </Map>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Programa
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <StyledImg src={programaImg} alt="Programa" />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Accordion item 3
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>Accordion 3 content</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Accordion item 4
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>Accordion 4 content</Card.Body>
          </Accordion.Collapse>
        </Card> */}
      </CustomAccordion>
    </CustomWrapper>
  );
};

export default Veinte;
