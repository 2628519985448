import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Seo from "../../components/Seo";
import { HOME } from "../../util/constants";
import * as data from "../../util/data.js";
import _ from "lodash";
import Spinner from "../../components/Spinner";
import styled from "styled-components";
import { Link } from "react-router-dom";

import fondoA from "../../assets/img/routes/veinte/fondo1.jpg";
import fondoB from "../../assets/img/routes/veinte/fondo2.jpg";

const extraBloksEnabled = false;

const ExtraBlocks = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0 10px 0",
  gap: 20,
  "@media screen and (min-width: 600px)": {
    flexDirection: "row",
  },
});

const MediumBlock = styled(Link)({
  background: `url(${fondoA}) no-repeat center`,
  backgroundSize: "cover",
  flex: 2,
  padding: "16px 30px",
  color: "white !important",
  "&:hover, &:hover *": {
    textDecoration: "none !important",
  },
});

const SmallBlock = styled("a")({
  background: `url(${fondoB}) no-repeat center`,
  backgroundSize: "cover",
  flex: 1,
  padding: "50px 30px",
  color: "white !important",
  "&:hover, &:hover *": {
    textDecoration: "none !important",
  },
});

const Comp11 = styled("div")({
  fontSize: "60px",
  fontWeight: "bold",
  lineHeight: "50px",
  letterSpacing: "-3px",
  span: {
    fontWeight: "normal",
    fontSize: "40px",
  },
});

const Comp12 = styled("div")({
  fontWeight: "bold",
  fontSize: "30px",
  letterSpacing: "-1px",
});

const Comp13 = styled("div")({
  fontSize: "24px",
  letterSpacing: "-1px",
});

const Comp14 = styled("div")({
  fontSize: "16px",
  textDecoration: "underline",
});

const Comp21 = styled("div")({
  fontSize: "24px",
  fontWeight: "bold",
  lineHeight: "31px",
});

const Comp22 = styled("div")({
  fontSize: "16px",
  textDecoration: "underline",
});

const Home = () => {
  const [banners, setBanners] = useState([]);
  const [featuredBooks, setFeaturedBooks] = useState([]);
  const [authors, setAuthors] = useState({});
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 1024
      ? 1024
      : window.innerWidth < 640
      ? 640
      : window.innerWidth
  );
  const [loading, setLoading] = useState(false);

  const handleResize = _.debounce(function rerenderFb() {
    if (windowWidth >= 640 && windowWidth <= 1024) {
      setWindowWidth(
        window.innerWidth > 1024
          ? 1024
          : window.innerWidth < 640
          ? 640
          : window.innerWidth
      );
    }
  }, 400);

  useEffect(() => {
    setLoading(true);
    const mainPromise = data.mainPromise();
    const authorsPromise = data.writersPromise();
    const booksPromise = data.booksPromise();
    Promise.all([mainPromise, authorsPromise, booksPromise])
      .then((responses) => {
        setBanners(JSON.parse(responses[0]).homepage.banners);
        setAuthors(JSON.parse(responses[1]));

        const booksArray = Object.entries(JSON.parse(responses[2])).map(
          (book) => ({ ...book[1], id: book[0] })
        );
        const featuredBooks = booksArray.filter(
          (book) => book.novelty === true
        );
        setFeaturedBooks(featuredBooks);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v16.0&appId=5723125661061793&autoLogAppEvents=1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Seo />
      <Spinner visible={loading} />
      <section className="st-content">
        <div className="index-banner">
          <Carousel>
            {banners.map((banner, index) => (
              <Carousel.Item key={`slide-${index}`}>
                <img
                  className="d-block w-100"
                  src={banner}
                  alt={`Slide ${index}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className="wrapper">
          {extraBloksEnabled && (
            <ExtraBlocks>
              <SmallBlock
                href="https://edicioneselnaranjo.com.mx/contents/Convocatoria_libro_album_Veinte_a_Leer_2023.pdf"
                target="_blank"
              >
                <Comp21>
                  Concurso
                  <br />
                  de libro-álbum
                  <br />
                  VEiNTE A LEER
                </Comp21>
                <br />
                <Comp22>Descarga la convocatoria aquí</Comp22>
              </SmallBlock>
            </ExtraBlocks>
          )}
        </div>
        <div className="featured-books wrapper" id="featured-books">
          {featuredBooks.map((book) => (
            <div className="cat-libro" key={book.name}>
              <a href={`libros/${book.id}`}>
                <div className="cover-container">
                  <img className="cover" src={book.cover} alt={book.name} />
                  <img
                    className="ribbon"
                    src={`${HOME}/img/icons/Novedad.png`}
                    alt="Novedad"
                  />
                </div>
                <div className="title">{book.name}</div>
                <div className="extra">
                  {authors[book.author] ? authors[book.author].name : "Autor"}
                </div>
              </a>
            </div>
          ))}
        </div>
        {windowWidth >= 640 && windowWidth <= 1024 && (
          <div className="socials wrapper cf">
            <div className="social-fb">
              <div
                className="fb-page"
                data-href="https://www.facebook.com/EdicionesElNaranjo.Oficial"
                data-show-posts="true"
                data-width={windowWidth}
                data-height="800"
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="true"
              >
                <blockquote
                  cite="https://www.facebook.com/EdicionesElNaranjo.Oficial"
                  class="fb-xfbml-parse-ignore"
                >
                  <a href="https://www.facebook.com/EdicionesElNaranjo.Oficial">
                    Ediciones El Naranjo
                  </a>
                </blockquote>
              </div>
            </div>
            <div className="social-tw">
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="el_naranjo"
                options={{ height: 800 }}
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Home;
