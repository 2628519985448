import { HELPER_URL, HOME } from "./constants";
export const FILES = {
  BOOKS: "/contents/libros.json",
  WRITERS: "/contents/autores.json",
  ARTISTS: "/contents/ilustradores.json",
  BLOG: "/contents/entradas.json",
  MAIN: "/contents/principal.json",
  CONTENTS: "/contents/uploads/",
  UPLOAD_ROUTES: {
    HOME: {
      BANNERS: "home/banners/",
    },
    BOOKS: {
      BANNERS: "books/banners/",
      COVERS: "books/covers/",
      GUIDES: "books/guias/",
      PRIZES: "books/prizes/",
    },
    ARTIST: "artist/",
    WRITER: "writer/",
    BLOG: {
      FEATURED: "blog/featured/",
      CONTENTS: "blog/contents/",
    },
  }
};

/* Gets a json file from the server */
export function requestJSON(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${url}?${randomme()}`);
    xhr.setRequestHeader('cache-control', 'no-cache, must-revalidate, post-check=0, pre-check=0');
    xhr.setRequestHeader('cache-control', 'max-age=0');
    xhr.setRequestHeader('expires', '0');
    xhr.setRequestHeader('expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
    xhr.setRequestHeader('pragma', 'no-cache');
    xhr.onload = () => resolve(xhr.responseText);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
}

/* Gets the specific files for each category */
export function booksPromise()    { return requestJSON(HELPER_URL + HOME + FILES.BOOKS); }
export function writersPromise()  { return requestJSON(HELPER_URL + HOME + FILES.WRITERS); }
export function artistsPromise()  { return requestJSON(HELPER_URL + HOME + FILES.ARTISTS); }
export function entriesPromise()  { return requestJSON(HELPER_URL + HOME + FILES.BLOG); }
export function mainPromise()     { return requestJSON(HELPER_URL + HOME + FILES.MAIN); }

// Random number generator
export function randomme() {
  return Math.floor((Math.random() * 9999999999) + 1111111111);
}

// This function cuts the text when a certain lenght is reached, and appends an ellipsis
export function ellipse(theStr, theLength) {
  var ellipsed = "";
  theStr = theStr.split(" ");
  for (var i = 0; i <= theStr.length - 1; i++) {
    if (ellipsed.length < theLength) {
      ellipsed += " " + theStr[i];
    } else {
      ellipsed += "...";
      break;
    }
  }
  return ellipsed;
}

export function extractTextFromHTML(string) {
  var span = document.createElement('span');
  span.innerHTML = string;
  return span.textContent || span.innerText;
};
