import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
} from "react-router-dom";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import Home from "../Home";
import Autores from "../Autores";
import Blog from "../Blog";
import Catalogo from "../Catalogo";
import Contacto from "../Contacto";
import Derechos from "../Derechos";
import Ebooks from "../Ebooks";
import Nosotros from "../Nosotros";
import PlanLector from "../PlanLector";
import Videos from "../Videos";
import Buscar from "../Buscar";
import Libro from "../Libro";
import Autor from "../Autor";
import Veinte from "../Veinte";
import { HOME } from "../../util/constants";

export default function AppRouter() {
  return (
    <Router>
      <AppBody />
    </Router>
  );
}

const AppBody = () => {
  let location = useLocation();

  useEffect(() => {
    if (window?.gtag) {
      window.gtag("event", "page_view", {
        page_title: location.pathname,
        page_location: `${HOME}${location.pathname}`,
      });
    }
  }, [location]);

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/autores" component={AutoresSwitch} />
        <Route path="/blog" component={Blog} />
        <Route path="/catalogo" component={Catalogo} />
        <Route path="/contacto" component={Contacto} />
        <Route path="/derechos" component={Derechos} />
        <Route path="/ebooks" component={Ebooks} />
        <Route path="/nosotros" component={Nosotros} />
        <Route path="/planlector" component={PlanLector} />
        <Route path="/videos" component={Videos} />
        <Route path="/buscar" component={Buscar} />
        <Route path="/libros" component={Libros} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </>
  );
};

function Libros() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:bookId`} component={Libro} />
      <Route path={`${path}/`} component={Catalogo} />
    </Switch>
  );
}

function AutoresSwitch() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:authorId`} component={Autor} />
      <Route path={`${path}/`} component={Autores} />
    </Switch>
  );
}
