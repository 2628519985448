import React from "react";
import { HOME } from "../../util/constants";
import * as data from "../../util/data";
import { Carousel } from "react-bootstrap";
import Spinner from "../../components/Spinner";
import Seo from "../../components/Seo";
import amazonIcon from "../../assets/img/icons/amazon_img.svg";
import ibooksIcon from "../../assets/img/icons/ibooks_img.svg";
import ebookIcon from "../../assets/img/icons/ebook_img.svg";

class Libro extends React.Component {
  constructor(props) {
    super(props);
    const initialState = {
      current: {},
      books: {},
      authors: {},
      artists: {},
      config: {},
      selectedBookbinding: "",
      loading: false,
    };
    this.state = initialState;
  }

  componentDidMount() {
    this.setState({ loading: true });
    const booksPromise = data.booksPromise();
    const authorsPromise = data.writersPromise();
    const artistsPromise = data.artistsPromise();
    const configPromise = data.mainPromise();
    Promise.all([
      booksPromise,
      authorsPromise,
      artistsPromise,
      configPromise,
    ]).then((responsesData) => {
      const currentBookData = JSON.parse(responsesData[0])[
        this.props.match.params.bookId
      ];
      if (currentBookData) {
        this.setState({
          current: currentBookData,
          books: JSON.parse(responsesData[0]),
          authors: JSON.parse(responsesData[1]),
          artists: JSON.parse(responsesData[2]),
          config: JSON.parse(responsesData[3]),
          selectedBookbinding:
            currentBookData.versions === "both"
              ? "paperback"
              : currentBookData.versions,
        });
      } else {
        this.props.history.push("/");
      }
      this.setState({ loading: false });
    });
  }

  createMarkup(rawstring) {
    return { __html: rawstring };
  }

  parseBookbinding(version) {
    switch (version) {
      case "paperback":
        return (
          <ul className="cf">
            <li
              className="button ld-tabs-pb"
              onClick={() => this.switchBookbinding("paperback")}
            >
              <strong
                className={
                  this.state.selectedBookbinding === "paperback"
                    ? "orange"
                    : "gray"
                }
              >
                Rústica
              </strong>
            </li>
          </ul>
        );
      case "hardcover":
        return (
          <ul className="cf">
            <li
              className="button ld-tabs-hc"
              onClick={() => this.switchBookbinding("hardcover")}
            >
              <strong
                className={
                  this.state.selectedBookbinding === "hardcover"
                    ? "orange"
                    : "gray"
                }
              >
                Tapa dura
              </strong>
            </li>
          </ul>
        );
      case "boardbook":
        return (
          <ul className="cf">
            <li
              className="button ld-tabs-bb"
              onClick={() => this.switchBookbinding("boardbook")}
            >
              <strong
                className={
                  this.state.selectedBookbinding === "boardbook"
                    ? "orange"
                    : "gray"
                }
              >
                Boardbook
              </strong>
            </li>
          </ul>
        );
      case "both":
        return (
          <div>
            <ul className="cf">
              <li
                className="button ld-tabs-pb"
                onClick={() => this.switchBookbinding("paperback")}
              >
                <strong
                  className={
                    this.state.selectedBookbinding === "paperback"
                      ? "orange"
                      : "gray"
                  }
                >
                  Rústica
                </strong>
              </li>
              <li
                className="button ld-tabs-hc"
                onClick={() => this.switchBookbinding("hardcover")}
              >
                <strong
                  className={
                    this.state.selectedBookbinding === "hardcover"
                      ? "orange"
                      : "gray"
                  }
                >
                  Tapa dura
                </strong>
              </li>
            </ul>
          </div>
        );
      default:
        break;
    }
  }

  switchBookbinding(version) {
    this.setState({ selectedBookbinding: version });
  }

  render() {
    return (
      <>
        <Seo
          title={this.state.current.name || ""}
          description={this.state.current.resumen || ""}
          image={this.state.current.cover || ""}
        />
        <Spinner visible={this.state.loading} />
        {this.state.current && (
          <section
            className="st-content cf libro"
            id="libro-content"
            style={{ display: "block" }}
          >
            <div className="libro-media wrapper cf">
              <div className="lm-portada">
                <img
                  src={this.state.current.cover}
                  id="cover"
                  alt={this.state.current.name}
                />
                <a
                  href={this.state.current.cover}
                  id="cover_download"
                  target="blank"
                  download
                >
                  <i className="fa fa-arrow-down" aria-hidden="true" />
                  &nbsp; Descarga la portada
                </a>
              </div>
              <div className="lm-galeria">
                <Carousel>
                  {this.state.current.banner &&
                    this.state.current.banner.map((banner, index) => {
                      if (banner.indexOf("youtube") < 0) {
                        return (
                          <Carousel.Item key={`slide-${index}`}>
                            <img
                              className="d-block w-100"
                              src={banner}
                              alt={`Slide ${index}`}
                            />
                          </Carousel.Item>
                        );
                      } else {
                        return (
                          <Carousel.Item key={`slide-${index}`}>
                            <iframe
                              width="560"
                              height="315"
                              src={`https://www.youtube.com/embed/${banner.replace(
                                "youtube:",
                                ""
                              )}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={this.state.current.name}
                            />
                          </Carousel.Item>
                        );
                      }
                    })}
                </Carousel>
              </div>
            </div>
            <div className="cf wrapper">
              <div className="libro-detalles cf">
                <div className="ld-datos">
                  <div className="ld-coleccion">
                    {this.state.config.configuracion && (
                      <img
                        src={
                          this.state.config.configuracion.colecciones[
                            this.state.current.collection_img
                          ]
                            ? this.state.config.configuracion.colecciones[
                                this.state.current.collection_img
                              ].url
                            : "Colección"
                        }
                        id="collection_img"
                        alt="Colección"
                      />
                    )}
                    <div className="serif" id="nivel_lector">
                      {this.state.config.configuracion &&
                        this.state.current.nivel_lector.map(
                          (nivel) =>
                            this.state.config.configuracion.nivel_lector[nivel]
                        )}
                    </div>
                  </div>
                </div>
                <div className="ld-tabs">
                  {/* Ediciones */}
                  {this.state.current &&
                    this.parseBookbinding(this.state.current.versions)}
                  <div className="tapadura">
                    <ul>
                      <li id="genres_hardcover">
                        {this.state.config.configuracion &&
                          this.state.current.genres.map(
                            (genre) =>
                              this.state.config.configuracion.genres[genre]
                          )}
                      </li>
                      <li>
                        {" "}
                        Año: <span rplc="year">
                          {this.state.current.year}
                        </span>{" "}
                      </li>
                      <li>
                        {" "}
                        Edición:{" "}
                        <span rplc="edition_number">
                          {this.state.current.edition_number}
                        </span>{" "}
                      </li>
                      <li>
                        {" "}
                        Páginas:{" "}
                        <span rplc="pages_hardcover">
                          {this.state.selectedBookbinding === "paperback"
                            ? this.state.current.pages_paperback
                            : this.state.current.pages_hardcover}
                        </span>{" "}
                      </li>
                      <li>
                        {" "}
                        Formato:{" "}
                        <span rplc="size_hardcover">
                          {this.state.selectedBookbinding === "paperback"
                            ? this.state.current.size_paperback
                            : this.state.current.size_hardcover}
                        </span>{" "}
                      </li>
                      <li>
                        {" "}
                        Impresión:{" "}
                        <span rplc="print">
                          {this.state.current.print}
                        </span>{" "}
                      </li>

                      <li>
                        {" "}
                        Peso:{" "}
                        <span rplc="weight_hardcover">
                          {this.state.selectedBookbinding === "paperback"
                            ? this.state.current.weight_paperback
                            : this.state.current.weight_hardcover}
                        </span>{" "}
                      </li>
                      <li>
                        {" "}
                        ISBN:{" "}
                        <span rplc="isbn_hardcover">
                          {this.state.selectedBookbinding === "paperback"
                            ? this.state.current.isbn_paperback
                            : this.state.current.isbn_hardcover}
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ld-temas">
                  {/* Temas */}
                  <strong className="orange">Temas</strong>
                  <ul rplc="themes">
                    {this.state.current.themes &&
                      this.state.current.themes.map((theme) => (
                        <li key={`topic-${theme}`}>{theme}</li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="libro-contenido">
                <h2 className="orange" rplc="name">
                  {this.state.current.name}
                </h2>
                <div className="lc-autores">
                  <div className="serif rplcr-container">
                    Texto de{" "}
                    {this.state.current.author &&
                      this.state.current.author.map((single_author, index) => (
                        <span
                          className="orange"
                          rplc="author"
                          key={`single-book-author-${single_author}`}
                        >
                          <a href={`/autores/${single_author}`}>
                            {this.state.authors[single_author]
                              ? `${this.state.authors[single_author].name}${
                                  index < this.state.current.author.length - 1
                                    ? ", "
                                    : ""
                                }`
                              : "Autor "}
                          </a>
                        </span>
                      ))}
                  </div>
                  <div className="serif rplcr-container">
                    Ilustraciones de{" "}
                    {this.state.current.artist &&
                      this.state.current.artist.map((single_artist, index) => (
                        <span
                          className="orange"
                          rplc="artist"
                          key={`single-book-artist-${single_artist}`}
                        >
                          <a href={`/autores/${single_artist}`}>
                            {this.state.artists[single_artist]
                              ? `${this.state.artists[single_artist].name}${
                                  index < this.state.current.artist.length - 1
                                    ? ", "
                                    : ""
                                }`
                              : "Ilustrador"}
                          </a>
                        </span>
                      ))}
                  </div>
                </div>
                <div
                  className="serif justified"
                  id="resumen"
                  dangerouslySetInnerHTML={this.createMarkup(
                    this.state.current.resumen
                  )}
                />
              </div>
              <div className="libro-misc cf">
                <div className="lmi-bloque">
                  {/* Tapa A */}
                  <div className="lmi-bloque-premios cf">
                    {/* Premios */}
                    <ul className="cf" rplc="prizes">
                      {this.state.current.prizes &&
                        this.state.current.prizes.map((prize) => (
                          <li key={`prize-${prize}`}>
                            <img src={prize} alt={prize} />
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="lmi-bloque-links">
                    {/* Enlaces */}
                    <a
                      href={this.state.current.start_reading}
                      rplc="start_reading"
                      id="start-reading"
                    >
                      <img
                        src={`${HOME}/img/icons/empieza_a_leerlo.svg`}
                        alt="Empieza a leerlo"
                      />
                      &nbsp;Empieza a leerlo
                    </a>
                    <a
                      href={this.state.current.download_guide}
                      rplc="download_guide"
                      target="blank"
                    >
                      <img
                        src={`${HOME}/img/icons/guia_de_lectura.svg`}
                        alt="Descarga la guía de lectura"
                      />
                      &nbsp;Descarga la guía de lectura
                    </a>
                  </div>
                </div>
                <div className="lmi-bloque cf">
                  {/* Tapa B */}
                  {this.state.current && (
                    <div className="lmi-bloque-tiendas">
                      <div className="rplcr-container">
                        <a
                          href={
                            this.state.selectedBookbinding === "paperback"
                              ? this.state.current.buy_paperback
                              : this.state.current.buy_hardcover
                          }
                          target="blank"
                        >
                          <img
                            src={`${HOME}/img/icons/precio_img.svg`}
                            alt="Comprar"
                          />
                        </a>
                        <a
                          href={
                            this.state.selectedBookbinding === "paperback"
                              ? this.state.current.buy_paperback
                              : this.state.current.buy_hardcover
                          }
                          target="blank"
                        >
                          <span>
                            {this.state.selectedBookbinding === "paperback"
                              ? this.state.current.price_paperback
                              : this.state.current.price_hardcover}
                          </span>
                        </a>
                      </div>
                    </div>
                  )}
                {
                  (this.state?.current?.amazon || this.state?.current?.ibooks) &&
                  <>
                    <div className="lmi-bloque-ebooks cf">
                      <img src={ebookIcon} alt="ebooks" />
                      <div>
                        {this.state?.current?.amazon && (
                          <a
                            href={this.state.current.amazon}
                            rplc="amazon"
                            target="blank"
                          >
                            <img src={amazonIcon} alt="Comprar en Amazon" />
                          </a>
                        )}
                        {this.state?.current?.ibooks && (
                          <a
                            href={this.state.current.ibooks}
                            rplc="ibooks"
                            target="blank"
                          >
                            <img src={ibooksIcon} alt="Comprar en iBooks" />
                          </a>
                        )}
                      </div>
                    </div>
                  </>
                }
                  <div className="lmi-bloque-sharing">
                    {/* <div className="addthis_inline_share_toolbox">Share stuff</div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="libro-relacionados">
                <strong className="orange">Títulos relacionados</strong>
                <div className="featured-books cf" id="featured-books">
                  {this.state.current.related &&
                    this.state.current.related.map(
                      (book) =>
                        this.state.books[book] && (
                          <div
                            className="cat-libro"
                            key={`related-${this.state.books[book].name}`}
                          >
                            <a href={`${book}`}>
                              <div className="cover-container">
                                <img
                                  className="cover"
                                  src={this.state.books[book].cover}
                                  alt={this.state.books[book].name}
                                />
                              </div>
                              <div className="title">
                                {this.state.books[book].name}
                              </div>
                            </a>
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default Libro;
