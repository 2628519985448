import React from 'react';
import Seo from '../../components/Seo';
import derechosPicture from '../../assets/img/routes/derechos/ForeignRights_ElNaranjo_2017.jpg';

const Derechos = () => {
  return (
    <>
      <Seo
        title="Derechos"
      />
      <section className="st-content wrapper page-rights">
        <div className="sidebar">
          <a href="https://edicioneselnaranjo.com.mx/contents/El_Naranjo_Foreign_Rights.pdf" target="blank">
            <img src={derechosPicture} alt="Derechos" />
          </a>
          <a href="https://edicioneselnaranjo.com.mx/contents/El_Naranjo_Foreign_Rights.pdf" target="blank">
            <strong>Descarga el catálogo de venta de derechos</strong>
          </a>
        </div>
        <div className="main-text text-section">
          <div className="parag txtblock">
            <span>Ediciones El Naranjo se ha distinguido por ir construyendo un catálogo de calidad con temas de interés para niños y jóvenes. Algunos de nuestros libros han llegado a países como China, Malta, Brasil y Estados Unidos. Conoce los títulos que están disponibles para venta de derechos.</span>
          </div>
          <div className="parag txtblock">
            <span>Para más información, por favor contactar a Paulina Delgado: <a href="mailto:paulina.elnaranjo@gmail.com">paulina.elnaranjo@gmail.com</a></span>
          </div>
        </div>
      </section>
    </>
  );
}

export default Derechos;
