import styled from 'styled-components';

export const FiltersList = styled.ul `
  & label {
    margin: 0;
  }
  & span {
    margin-top: 15px;
  }
`;
