import styled from 'styled-components';

export const SubmenuButton = styled.button`
    background: none;
    border: none;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
`;