import React from 'react';
import GoogleMapReact from 'google-map-react';
import marker from '../../assets/img/icons/marker.png';
import Seo from '../../components/Seo';

const mapData = {
  center: {
    lat: 19.316623, lng: -99.222395
  },
  zoom: 17
};

const Marker = () => {
  return(
    <img alt="El Naranjo" src={marker} />
  );
}

function Contacto() {
  return (
    <>
      <Seo
        title="Contacto"
      />
      <section className="st-content wrapper cf">
        <div className="cont left-col">
          <div className="text-section cont-phonemails">
            <div className="parag">
              <h3>Teléfono</h3>
              <span className="breakall">
                <a href="tel:+525556521974">+52 (55) 5652 1974</a>
              </span>
            </div>
            <div className="parag breakall">
              <h3>Ventas</h3>
              <span>
                <a href="mailto:ventas@edicioneselnaranjo.com.mx">ventas@edicioneselnaranjo.com.mx</a>
              </span>
            </div>
            <div className="parag breakall">
              <h3>Servicios editoriales</h3>
              <span>
                <a href="mailto:elnaranjo@edicioneselnaranjo.com.mx">elnaranjo@edicioneselnaranjo.com.mx</a>
              </span>
            </div>
            <div className="parag breakall">
              <h3>Venta de derechos</h3>
              <span>
                <a href="mailto:pdelgado@edicioneselnaranjo.com.mx">pdelgado@edicioneselnaranjo.com.mx</a>
              </span>
            </div>
          </div>

          <div className="cont-text cont-recepcion">
            <div className="text-section">
              <div className="parag">
                <h2>Recepción de portafolios</h2>
                <span>Puedes enviar tu portafolio de ilustración al correo <div className="breakall"><a href="mailto:portafolios.elnaranjo@gmail.com">portafolios.elnaranjo@gmail.com</a>,</div> te pedimos que el archivo no pese más de 8 MB.</span>
                <h2>Recepción de manuscritos y libros ilustrados</h2>
                <span className="txtblock">CONVOCATORIA CERRADA HASTA NUEVO AVISO. Publicaremos las fechas de la siguiente convocatoria por este medio. Se pide amablemente no preguntar por correo electrónico.</span>
              </div>
            </div>
            <div className="text-section">
              <a href="contents/Especificaciones_para_publicar_en_Ediciones_El_Naranjo.pdf?1" target="_blank">Descargar especificaciones para publicar</a>
            </div>
          </div>

          <div className="text-section cont-location">
            <div className="parag">
              <h2>Ubicación</h2>
              <span>
                Avenida México 570,<br />
                Col. San Jerónimo Aculco,<br />
                C.&nbsp;P. 10400, Ciudad de México.
              </span>
            </div>
            <div className="cont-map">
              <div id="map">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyCX3-oCL8UHQ7VyE4hK_XjQwALX6SWXcbQ" }}
                  defaultCenter={mapData.center}
                  defaultZoom={mapData.zoom}
                >
                  <Marker
                    lat={19.31725}
                    lng={-99.222655}
                    text="El Naranjo"
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>

        <div className="cont right-col">
          <div className="cont-text cont-distribucion cf">
            <div className="text-section">
              <h2>Distribución internacional</h2>
              <div className="parag distrib">
                <h3>ESTADOS UNIDOS</h3>
                <strong>California</strong><br />
                <i className="bookstorename">LA librería</i><br />
                4732 1/2 W Washington Blvd<br />
                Los Angeles, CA 90016<br />
                Tel. (310) 295 1501<br />
                <a href="mailto:info@la-libreria.net">info@la-libreria.net</a><br />
                <a href="www.la-libreria.net">www.la-libreria.net</a>

                <strong>Minneapolis</strong><br />
                <i className="bookstorename">Cazalibros</i><br />
                Minneapolis, MN 55416<br />
                Tel. (612) 245 1101<br />
                <a href="mailto:caza-libros@outlook.com">caza-libros@outlook.com</a><br />
                <a href="www.caza-libros.com">www.caza-libros.com</a>

                <strong>Nueva York</strong><br />
                <i className="bookstorename">The Bilingual Publications Co.</i><br />
                270 Lafayette Street, Suite 705<br />
                New York, NY 10012<br />
                Tel. (212) 431 3500<br />
                <a href="mailto:bilingualspanish@gmail.com">bilingualspanish@gmail.com</a>

                <strong>Florida</strong><br />
                <i className="bookstorename">Cinco Books</i><br />
                23370 SW 113TH CT<br />
                Princeton, FL 33032<br />
                Tel. (786) 410 5020<br />
                <a href="mailto:info@cincobooks.com">info@cincobooks.com</a><br />
                <a href="www.cincobooks.com">www.cincobooks.com</a>

                <strong>Colorado</strong><br />
                <i className="bookstorename">Chulainn Publishing Corp.</i><br />
                7436 Indian Wells Way<br />
                Lone Tree, CO 80124-4211<br />
                Tel. (303) 790 4112 <br />
                <a href="mailto:e.farro@chulainnlibros.com">e.farro@chulainnlibros.com</a>

                <strong>Nueva Jersey</strong><br />
                <i className="bookstorename">Lectorum Publications Inc.</i><br />
                205 Chubb Ave.<br />
                Lyndhurst, NJ 07071<br />
                Tel. (201) 559 2237<br />
                <a href="mailto:lectorum@lectorum.com">lectorum@lectorum.com</a><br />
                <a href="www.lectorum.com">www.lectorum.com</a>
              </div>

              <div className="parag distrib">
                <h3>COLOMBIA</h3>
                <strong>Bogotá</strong><br />
                <i className="bookstorename">Fondo de Cultura Económica</i><br />
                Centro Cultural Gabriel García Márquez<br />
                Calle 11 no. 5 - 60<br />
                Tel. (571) 283 2200<br />
                <a href="www.fce.com.co/">www.fce.com.co/</a>
              </div>

              <div className="parag distrib">
                <h3>CHILE</h3>
                <strong>Santiago de Chile</strong><br />
                <i className="bookstorename">Fondo de Cultura Económica</i><br />
                Librería Gonzalo Rojas <br />
                Paseo Bulnes 152<br />
                Tel. (562) 5944 100, 5944 135 <br />
                <a href="www.fcechile.cl">www.fcechile.cl</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contacto;
