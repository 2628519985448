import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ellipse } from '../../util/data';
import placeholderImg from '../../assets/img/placeholder_image.png';

const defaultDescription = "Editorial mexicana de libros para niños y jóvenes. Publicamos y editamos con calidad desde la inteligencia y el corazón. ¡Sácale jugo a la lectura!";

const Seo = ({ title, description, image }) => {
  const [parsedDescription, setParsedDescription] = useState(defaultDescription);
  const [parsedTitle, setParsedTitle] = useState(title);

  useEffect(() => {
    setParsedDescription(
      description ? ellipse(description, 160).trim() : defaultDescription
    );
  }, [description]);

  useEffect(() => {
    setParsedTitle(
      title ? `${`${ellipse(title, 100)} • `}Ediciones El Naranjo` : "Ediciones El Naranjo"
    );
  }, [title]);

  return (
    <Helmet>
      <title>{parsedTitle}</title>
      <meta name="description" content={parsedDescription} />
      <meta property="og:title" content={parsedTitle} />
      <meta property="og:description" content={parsedDescription} />
      <meta property="og:image" content={image || placeholderImg} />
    </Helmet>
  )
}

export default Seo;
