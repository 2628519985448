import React from 'react';
import Seo from '../../components/Seo';
import Spinner from '../../components/Spinner';
import * as data from '../../util/data';

class Autores extends React.Component {
  constructor(props) {
    super(props);
    const initialState = {
      writers: [],
      illustrators: [],
      writersInitials: [],
      illustratorsInitials: [],
      displaying: "writers",
      filters: {
        initial: 'all',
      },
      loading: false,
    }
    this.state = initialState;
  }

  componentDidMount() {
    this.setState({ loading: true });
    const writersPromise = data.writersPromise();
    const illustratorsPromise = data.artistsPromise();
    Promise.all([writersPromise, illustratorsPromise]).then(responsesData => {
      const writersArray = Object.entries(JSON.parse(responsesData[0])).map((value) => (
        { ...value[1], id: value[0] }
      )).filter(value => value.visible === true);
      const illustratorsArray = Object.entries(JSON.parse(responsesData[1])).map((value) => (
        { ...value[1], id: value[0] }
      )).filter(value => value.visible === true);
      let writersInitials = writersArray.map(value => (value.name.substr(0, 1)));
      writersInitials = [...new Set(writersInitials)];
      let illustratorsInitials = illustratorsArray.map(value => (value.name.substr(0, 1)));
      illustratorsInitials = [...new Set(illustratorsInitials)];
      this.setState({
        writers: writersArray,
        illustrators: illustratorsArray,
        writersInitials: writersInitials,
        illustratorsInitials: illustratorsInitials,
      });
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        <Seo
          title="Autores"
          description="Los mejores escritores e ilustradores de libros para niños y jóvenes."
        />
        <section className="st-content wrapper cf">
          <Spinner visible={this.state.loading} />
          <div className="filterLinks">
            <span
              onClick={() => this.setState({ displaying: "writers", filters: { initial: "all" } })}
              className={this.state.displaying === "writers" ? "font-weight-bold" : ""}
            >
              Escritores
            </span>
            <span
              onClick={() => this.setState({ displaying: "illustrators", filters: { initial: "all" } })}
              className={this.state.displaying === "illustrators" ? "font-weight-bold" : ""}
            >
              Ilustradores
            </span>
          </div>
          <div className="aut-index" id="aut-index">
            {
              this.state.writersInitials &&
              this.state.illustratorsInitials &&
              this.state.displaying &&
              this.state[`${this.state.displaying}Initials`].map(value => (
                <label
                  key={`initial-${value}`}
                  onClick={() => this.state.filters.initial !== value ? this.setState({ filters: { initial: value } }): this.setState({ filters: { initial: "all" } })}
                  className={this.state.filters.initial === value ? "font-weight-bold" : ""}
                >
                  <div>{value}</div>
                </label>
              ))
            }
          </div>
          <div className="aut-list" id="aut-list">
            {
              this.state.writers &&
              this.state.illustrators &&
              this.state[this.state.displaying]
                .filter(fValue => (
                  this.state.filters.initial !== "all" ?
                    fValue.name.substr(0, 1) === this.state.filters.initial :
                    fValue.name
                ))
                .map(value => (
                  <div className="aut-single" key={`aut-${value.id}`}>
                    <a href={`/autores/${value.id}`}>
                      <div className="photo">
                        <img
                          src={value.picture}
                          alt={value.name}
                        />
                      </div>
                      <div className="info">
                        <div className="header">
                          {value.name}
                        </div>
                        <div className="excerpt">
                          {
                            data.ellipse(data.extractTextFromHTML(value.bio), 70)
                          }
                        </div>
                        <span>Ver más</span>
                      </div>
                    </a>
                  </div>
                ))
            }
          </div>
        </section>
      </>
    )
  }
}

export default Autores;
