import React, { useEffect, useState } from 'react';
import Seo from '../../components/Seo';
import Spinner from '../../components/Spinner';
import * as data from '../../util/data';

const Catalogo = () => {
  const [loading, setLoading] = useState(false)
  const [books, setBooks] = useState([])
  const [filters, setFilters] = useState({
    novelties: false,
    ages: "0",
    genres: "allGenres"
  })

  useEffect(() => {
    setLoading(true)
    getBooks()
  }, [])

  const getBooks = () => {
    const onSuccess = (data) => {
      const booksArray = Object.entries(JSON.parse(data)).map((book) => (
        { ...book[1], id: book[0] }
      ));
      setBooks(booksArray)
      setLoading(false)
    }
    const booksPromise = data.booksPromise();
    booksPromise.then(onSuccess)
  }

  return (
    <>
      <Seo
        title="Catálogo"
        description="El catálogo completo de Ediciones El Naranjo: todos los libros de narrativa, poesía, libros para bebés, libros informativos, libros álbum."
      />
      <Spinner visible={loading} />
      <section className="st-content wrapper cf">
        <div className="cat-filters cf">
          <label
            id="novedad"
            className="switch"
            onClick={() => setFilters({ ...filters, novelties: !filters.novelties })}
          >
            <div className={`switch_label ${filters.novelties === true ? "active" : ""}`}>Novedades</div>
          </label>
          <div id="filterA">
            <span>Edad</span>
            <ul>
              <li onClick={() => setFilters({...filters, ages: "0" })}>
                <label className={filters.ages === "0" ? "active" : ""}>
                <input name="edad" type="radio" value="0" data-text="Todas" />Todas</label>
              </li>
              <li onClick={() => setFilters({...filters, ages: "1" })}>
                <label className={filters.ages === "1" ? "active" : ""}>
                <input name="edad" type="radio" value="1" data-text="1 - 5" />1 - 5</label>
              </li>
              <li onClick={() => setFilters({...filters, ages: "2" })}>
                <label className={filters.ages === "2" ? "active" : ""}>
                <input name="edad" type="radio" value="2" data-text="6 - 10" />6 - 10</label>
              </li>
              <li onClick={() => setFilters({...filters, ages: "3" })}>
                <label className={filters.ages === "3" ? "active" : ""}>
                <input name="edad" type="radio" value="3" data-text="11 - 14" />11 - 14</label>
              </li>
              <li onClick={() => setFilters({...filters, ages: "4" })}>
                <label className={filters.ages === "4" ? "active" : ""}>
                <input name="edad" type="radio" value="4" data-text="15+" />15+</label>
              </li>
            </ul>
            <div className="dropdown-label"></div>
          </div>
          <div id="filterB">
            <span>Género</span>
            <ul>
              <li onClick={() => setFilters({ ...filters, genres: "allGenres" })}>
                <label className={filters.genres === "allGenres" ? "active" 
                : ""}><input name="genero" type="radio" value="allGenres" data-text="Todos" />Todos</label>
              </li>
              <li onClick={() => setFilters({ ...filters, genres: "boardbook" })}>
                <label className={filters.genres === "boardbook" ? "active" 
                : ""}><input name="genero" type="radio" value="boardbook" data-text="Boardbook" />Boardbook</label>
              </li>
              <li onClick={() => setFilters({ ...filters, genres: "cuento" })}>
                <label className={filters.genres === "cuento" ? "active" 
                : ""}><input name="genero" type="radio" value="cuento" data-text="Cuento" />Cuento</label>
              </li>
              <li onClick={() => setFilters({ ...filters, genres: "informativo" })}>
                <label className={filters.genres === "informativo" ? "active" 
                : ""}><input name="genero" type="radio" value="informativo" data-text="Informativo" />Informativo</label>
              </li>
              <li onClick={() => setFilters({ ...filters, genres: "novela" })}>
                <label className={filters.genres === "novela" ? "active" 
                : ""}><input name="genero" type="radio" value="novela" data-text="Novela" />Novela</label>
              </li>
              <li onClick={() => setFilters({ ...filters, genres: "poesia" })}>
                <label className={filters.genres === "poesia" ? "active" 
                : ""}><input name="genero" type="radio" value="poesia" data-text="Poesía" />Poesía</label>
              </li>
              <li onClick={() => setFilters({ ...filters, genres: "otro" })}>
                <label className={filters.genres === "otro" ? "active" 
                : ""}><input name="genero" type="radio" value="otro" data-text="Otro" />Otro</label>
              </li>
            </ul>
            <div className="dropdown-label"></div>
          </div>
        </div>
        <div className="cat-list" id="cat-list">
          {
            books &&
            books
              .filter(book => (!filters.novelties ? true : book.novelty === filters.novelties))
              .filter(book => (filters.ages !== "0" ? book.ages.includes(filters.ages) : book.ages))
              .filter(book => (filters.genres !== "allGenres" ? book.genres.includes(filters.genres) : book.genres))
              .map(book => (
              <div className="cat-libro" key={`catalog-${book.id}`}>
                <a href={`/libros/${book.id}`}>
                  <img
                    className="cover"
                    src={`${book.cover}`}
                    alt={book.name}
                  />
                  <div className="title">{book.name}</div>
                </a>
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default Catalogo;
