import React from 'react';
import Seo from '../../components/Seo';
import nosotrosTall from "../../assets/img/routes/nosotros/nosotros_tall.jpg";
import nosotrosShort from "../../assets/img/routes/nosotros/nosotros_short.jpg";

function Nosotros (props) {
  return(
    <>
      <Seo
        title="Nosotros"
        description="Ediciones El Naranjo es una editorial mexicana que publica libros para niños y jóvenes de gran calidad."
      />
      <section className="st-content wrapper cf">
        <div className="nosot-picture">
          <picture>
            <source
              srcSet={nosotrosTall}
              media="(min-width: 640px)"
            />
            <source
              srcSet={nosotrosShort}
              media="(max-width: 639px)"
            />
            <img alt="Nosotros" src="../../assets/img/routes/nosotros/nosotros_short.jpg" />
          </picture>
        </div>

        <div className="nosot-text text-section">
          <div className="quote">
            El Naranjo es un espacio para imaginar y crear desde la inteligencia y el corazón.
          </div>
          <div className="parag txtblock">
            <span>Ediciones El Naranjo es una editorial mexicana fundada en 1994, que empezó a publicar libros para niños y jóvenes bajo su propio sello en 2003. Ha editado más de cien títulos de narrativa, poesía, arte, tradiciones, historia, naturaleza y literatura clásica, siempre buscando un enfoque lúdico. Su objetivo primordial es construir una estética propia para contribuir a la formación de lectores críticos y creativos mediante obras que despierten su sensibilidad e interés al abordar temáticas con las que se sientan identificados o que les planteen nuevas inquietudes.</span>
          </div>
          <div className="parag txtblock">
            <span>En su trayectoria los libros de Ediciones El Naranjo han recibido el reconocimiento no solo de los lectores, sino también de importantes instituciones. Desde 2003, más de veinte títulos han sido seleccionados para el Programa Bibliotecas de Aula y Escolares, de la Secretaría de Educación Pública, y forman parte de todas las bibliotecas de las escuelas públicas de nuestro país.</span>
          </div>
          <div className="parag txtblock">
            <span>En 2022 la Feria Internacional del Libro Infantil de Bolonia —la más importante en el ámbito de la literatura infantil y juvenil del mundo— ha galardonado a Ediciones El Naranjo con el Premio BOP (Bologna Best Children’s Publisher of the Year Prize) como mejor editorial de Centro y Sudamérica.</span>
          </div>
        </div>
      </section>
    </>
  );
}

export default Nosotros;
